import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createNewCategory, editCloseMessage, selectCategoriesErrorMessage, selectCategoriesSuccessMessage } from '../../redux/reducers/categoriesReducer';

const NewCategoryModal = ({ showNewEntityModal, handleClose, handleSearch }) => {
    // Llamadas a API y Redux
    const dispatch = useDispatch();

    //Mensaje de exito o error
    const successMessage = useSelector(selectCategoriesSuccessMessage);
    const errorMessage = useSelector(selectCategoriesErrorMessage);

    const handleCloseMessage = () => {
        handleClose();
        setFormData({
            name: ''
        })
        dispatch(editCloseMessage());
        handleSearch();
    }

    //FormData
    const [formData, setFormData] = useState({
        name: ''
    });

    const handleFormChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(createNewCategory(formData));
    };

    return (
        <div>
            <Modal show={showNewEntityModal} onHide={handleClose} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva categoria</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ marginLeft: '8px' }}>
                    <form style={{ display: 'contents' }} onSubmit={handleSubmit}>
                        <div>
                            <Row className='pb-2'>
                                <Col>
                                    <InputGroup>
                                        <InputGroup.Text>Nombre:</InputGroup.Text>
                                        <Form.Control
                                            name="name"
                                            type="text"
                                            placeholder="Nombre..."
                                            value={formData.name}
                                            onChange={handleFormChange}
                                            style={{ marginTop: 0, height: '100%' }}
                                            required
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                        </div>
                        <Modal.Footer>
                            <Button variant="primary" type='submit'>Crear categoría</Button>
                        </Modal.Footer>
                    </form>
                </Modal.Body>
                <Modal show={!!successMessage || !!errorMessage} onHide={handleCloseMessage}>
                    <Modal.Header closeButton>
                        <Modal.Title>{errorMessage ? 'Error en la creación' : 'Creación exitosa'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {successMessage || errorMessage}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseMessage}>Cerrar</Button>
                    </Modal.Footer>
                </Modal>
            </Modal >
        </div>
    );
}

export default NewCategoryModal;