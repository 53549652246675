import { createSlice } from '@reduxjs/toolkit';
import { withAuthentication } from '../middlewares';
import { createCategory, deleteCategoryById, editCategoryById, getCategoryById, getCategoriesWithFilters } from '../actions/categoriesThunks';

const initialState = {
    data: [],
    category: null,
    loading: false,
    error: null,
    successMessage: null,
    errorMessage: null,
};

const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        //Traer todos las categorias
        fetchCategoriesStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchCategoriesSuccess(state, action) {
            state.loading = false;
            state.data = action.payload;
        },
        fetchCategoriesFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        //Traer una categoria para edición/detalle
        fetchCategoryStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchCategorySuccess(state, action) {
            state.loading = false;
            state.category = action.payload;
        },
        fetchCategoryFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        clearCategory(state) {
            state.category = null;
        },
        //Editar una categoria
        editCategoryStart(state) {
            state.loading = true;
            state.error = null;
        },
        editCategorySuccess(state, action) {
            state.loading = false;
            state.category = action.payload;
            state.successMessage = 'Operación exitosa';
            state.errorMessage = null;
        },
        editCategoryFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.errorMessage = 'Error en la operación';
            state.successMessage = null;
        },
        editCloseMessage(state) {
            state.successMessage = null;
            state.errorMessage = null;
        },
        //Borrar una categoria
        deleteCategoryStart(state) {
            state.loading = true;
            state.error = null;
        },
        deleteCategorySuccess(state, action) {
            state.loading = false;
            state.error = null;

            const categoryuuid = action.payload;
            const deletedCategoryIndex = state.data.findIndex(category => category.uuid === categoryuuid);

            if (deletedCategoryIndex !== -1) {
                state.data.splice(deletedCategoryIndex, 1);
                if (state.category && state.category.uuid === categoryuuid) {
                    state.category = null;
                }
            }
        },
        deleteCategoryFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        //Crear categoria
        createNewCategoryStart(state) {
            state.loading = true;
            state.error = null;
        },
        createNewCategorySuccess(state, action) {
            state.loading = false;
            state.category = action.payload;
            state.successMessage = 'Categoría creado exitosamente';
            state.errorMessage = null;
        },
        createNewCategoryFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.errorMessage = 'Error al crear categoría';
            state.successMessage = null;
        },
    },
});

export const fetchCategories = withAuthentication((authToken, ...args) => async (dispatch, getState) => {
    dispatch(fetchCategoriesStart());
    try {
        const response = await getCategoriesWithFilters(authToken, ...args);
        const categories = response.categories || [];
        dispatch(fetchCategoriesSuccess(categories));
    } catch (error) {
        dispatch(fetchCategoriesFailure(error.message));
    }
});

export const fetchCategoryById = withAuthentication((authToken, categoryuuid) => async (dispatch, getState) => {
    dispatch(fetchCategoryStart());
    try {
        const category = await getCategoryById(authToken, categoryuuid);
        dispatch(fetchCategorySuccess(category));
    } catch (error) {
        dispatch(fetchCategoryFailure(error.message));
    }
});

export const createNewCategory = withAuthentication((authToken, newCategory) => async (dispatch, getState) => {
    dispatch(createNewCategoryStart());
    try {
        const response = await createCategory(authToken, newCategory);
        const category = response.category;
        dispatch(createNewCategorySuccess(category));
    } catch (error) {
        dispatch(createNewCategoryFailure(error.message));
    }
});

export const editCategory = withAuthentication((authToken, categoryuuid, editedCategory) => async (dispatch, getState) => {
    dispatch(editCategoryStart());
    try {
        const response = await editCategoryById(authToken, categoryuuid, editedCategory);
        const category = response.category || {};
        dispatch(editCategorySuccess(category));
    } catch (error) {
        dispatch(editCategoryFailure(error.message));
    }
});

export const deleteCategory = withAuthentication((authToken, categoryuuid) => async (dispatch, getState) => {
    dispatch(deleteCategoryStart());
    try {
        const response = await deleteCategoryById(authToken, categoryuuid);
        dispatch(deleteCategorySuccess(response.categoryuuid));
    } catch (error) {
        dispatch(deleteCategoryFailure(error.message));
    }
});

export const {
    fetchCategoriesStart,
    fetchCategoriesSuccess,
    fetchCategoriesFailure,
    fetchCategoryStart,
    fetchCategorySuccess,
    fetchCategoryFailure,
    clearCategory,
    editCategoryStart,
    editCategorySuccess,
    editCategoryFailure,
    editCloseMessage,
    deleteCategoryStart,
    deleteCategorySuccess,
    deleteCategoryFailure,
    createNewCategoryStart,
    createNewCategorySuccess,
    createNewCategoryFailure,
} = categoriesSlice.actions;

export const selectCategories = (state) => state.categories.data;
export const selectCategoryById = (state) => state.categories.category;
export const selectCategoriesLoading = (state) => state.categories.loading;
export const selectCategoriesError = (state) => state.categories.error;
export const selectCategoriesSuccessMessage = (state) => state.categories.successMessage;
export const selectCategoriesErrorMessage = (state) => state.categories.errorMessage;

export default categoriesSlice.reducer;
