import React, { useState } from 'react';
import { Form, Row, Col, InputGroup, Button } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';

const SearchBar = ({ onSearch, parameterName }) => {
    const [searchText, setSearchText] = useState('');

    const handleChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        onSearch(searchText);
    };

    return (
        <div>
            <form onSubmit={handleSubmit} style={{ display: 'contents' }}>
                <Row className="align-items-center justify-content-center">
                    <Col>
                        <InputGroup>
                            <Form.Control
                                type="text"
                                placeholder={`Buscar por ${parameterName}...`}
                                value={searchText}
                                onChange={handleChange}
                                style={{ marginTop: 0 }}
                                aria-describedby="search-button"
                            />
                            <Button type="submit" variant='outline-dark' id="search-button" style={{ color: 'white', borderColor: 'white' }}>
                                <Search />
                            </Button>
                        </InputGroup>
                    </Col>
                </Row>
            </form>
        </div >
    );
};

export default SearchBar;