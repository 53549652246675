import { createSlice } from '@reduxjs/toolkit';
import { getAccesses } from '../actions/accessesThunks';
import { withAuthentication } from '../middlewares';

const initialState = {
    data: [],
    loading: false,
    error: null,
};

const accessesSlice = createSlice({
    name: 'accesses',
    initialState,
    reducers: {
        fetchAccessesStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchAccessesSuccess(state, action) {
            state.loading = false;
            state.data = action.payload;
        },
        fetchAccessesFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        clearAccesses(state) {
            state.data = null;
        }
    },
});

export const fetchAccesses = withAuthentication((authToken) => async (dispatch, getState) => {
    dispatch(fetchAccessesStart());
    try {
        const accesses = await getAccesses(authToken)
        dispatch(fetchAccessesSuccess(accesses));
    } catch (e) {
        dispatch(fetchAccessesFailure(e.message));
    }
});

export const {
    fetchAccessesStart,
    fetchAccessesSuccess,
    fetchAccessesFailure,
    clearAccesses
} = accessesSlice.actions;

export const selectAccesses = (state) => state.accesses.data;
export const selectAccessesLoading = (state) => state.accesses.loading;
export const selectAccessesError = (state) => state.accesses.error;

export default accessesSlice.reducer;