import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { editClient, editCloseMessage, selectClientsErrorMessage, selectClientsSuccessMessage } from '../../redux/reducers/clientsReducer';

const EditClientModal = ({ showEditModal, handleClose, handleSearch, client }) => {
    // Llamadas a API y Redux
    const dispatch = useDispatch();

    //Mensaje de exito o error
    const successMessage = useSelector(selectClientsSuccessMessage);
    const errorMessage = useSelector(selectClientsErrorMessage);

    const handleCloseMessage = () => {
        handleClose();
        setFormData({
            business_name: '',
            contact_name: '',
            cuit: '',
            phone: '',
            address: '',
            detail: ''
        })
        dispatch(editCloseMessage());
        handleSearch();
    }

    //Nombre y descripcion
    const [formData, setFormData] = useState({
        business_name: '',
        contact_name: '',
        cuit: '',
        phone: '',
        address: '',
        detail: ''
    });

    const handleFormChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    useEffect(() => {
        if (client) {
            setFormData({
                business_name: client.business_name,
                contact_name: client.contact_name,
                cuit: client.cuit,
                phone: client.phone,
                address: client.address,
                detail: client.detail
            });
            setInitialFormData({
                business_name: client.business_name,
                contact_name: client.contact_name,
                cuit: client.cuit,
                phone: client.phone,
                address: client.address,
                detail: client.detail
            });
        }
    }, [client]);

    //Cambios de formulario
    const [initialFormData, setInitialFormData] = useState();
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        if (initialFormData) {
            const hasChanges = JSON.stringify(initialFormData) !== JSON.stringify(formData);
            setIsChanged(hasChanges);
        }
    }, [formData, initialFormData]);

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(editClient(client.uuid, formData));
    };

    return (
        <div>
            <Modal show={showEditModal} onHide={handleClose} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Nuevo cliente</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ marginLeft: '8px' }}>
                    <form style={{ display: 'contents' }} onSubmit={handleSubmit}>
                        <div>
                            <Row className='pb-2'>
                                <Col sm='4' md='4' lg='4'>
                                    <InputGroup>
                                        <InputGroup.Text>Razón social:</InputGroup.Text>
                                        <Form.Control
                                            name='business_name'
                                            type="text"
                                            placeholder="Razón social..."
                                            value={formData.business_name}
                                            onChange={handleFormChange}
                                            style={{ marginTop: 0, height: '100%' }}
                                            required
                                        />
                                    </InputGroup>
                                </Col>
                                <Col sm='4' md='4' lg='4'>
                                    <InputGroup>
                                        <InputGroup.Text>Contacto:</InputGroup.Text>
                                        <Form.Control
                                            name='contact_name'
                                            type="text"
                                            placeholder="Nombre de contacto..."
                                            value={formData.contact_name}
                                            onChange={handleFormChange}
                                            style={{ marginTop: 0, height: '100%' }}
                                            required
                                        />
                                    </InputGroup>
                                </Col>
                                <Col sm='4' md='4' lg='4'>
                                    <InputGroup>
                                        <InputGroup.Text>CUIT:</InputGroup.Text>
                                        <Form.Control
                                            name='cuit'
                                            type="text"
                                            placeholder="CUIT..."
                                            value={formData.cuit}
                                            onChange={handleFormChange}
                                            style={{ marginTop: 0, height: '100%' }}
                                            required
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row className='pb-4'>
                                <Col sm='3' md='3' lg='3'>
                                    <InputGroup>
                                        <InputGroup.Text>Teléfono:</InputGroup.Text>
                                        <Form.Control
                                            name='phone'
                                            type="number"
                                            placeholder="Teléfono..."
                                            value={formData.phone}
                                            onChange={handleFormChange}
                                            style={{ marginTop: 0, height: '100%' }}
                                            required
                                        />
                                    </InputGroup>
                                </Col>
                                <Col sm='9' md='9' lg='9'>
                                    <InputGroup>
                                        <InputGroup.Text>Dirección:</InputGroup.Text>
                                        <Form.Control
                                            name='address'
                                            type="text"
                                            placeholder="Dirección..."
                                            value={formData.address}
                                            onChange={handleFormChange}
                                            style={{ marginTop: 0, height: '100%' }}
                                            required
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row className='pb-4'>
                                <Col sm='12' md='12' lg='12'>
                                    <InputGroup>
                                        <InputGroup.Text>Observaciones:</InputGroup.Text>
                                        <Form.Control
                                            name='detail'
                                            type="text"
                                            placeholder="Observaciones..."
                                            value={formData.detail}
                                            onChange={handleFormChange}
                                            style={{ marginTop: 0, height: '100%' }}
                                            required
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                        </div>
                        <Modal.Footer>
                            {!isChanged && (
                                <p className="form-text text-muted">
                                    Debe realizar cambios para guardar
                                </p>
                            )}
                            <Button variant="primary" style={{ marginTop: '0px' }} onClick={handleSubmit} disabled={!isChanged}>Guardar cambios</Button>
                        </Modal.Footer>
                    </form>
                </Modal.Body>
                <Modal show={!!successMessage || !!errorMessage} onHide={handleCloseMessage}>
                    <Modal.Header closeButton>
                        <Modal.Title>{errorMessage ? 'Error en la modificación' : 'Modificación exitosa'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {successMessage || errorMessage}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseMessage}>Cerrar</Button>
                    </Modal.Footer>
                </Modal>
            </Modal >


        </div>
    );
}

export default EditClientModal;