import React, { useState, useEffect } from 'react';
import { Container, Nav, Row, Col, Card, Button, Modal, Form } from 'react-bootstrap';
import { Outlet, useNavigate, Link, useLocation } from 'react-router-dom';
import './styles/Layout.css';
import img_logo from '../assets/metzer-logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { clearUserLogged, selectLoggedUserProfile, selectLoggedUserUserName, selectToken } from '../redux/reducers/authReducer';
import { PersonCircle } from 'react-bootstrap-icons';
import { Alert } from 'react-bootstrap';
import { changePasswordWithAuth } from '../redux/actions/authThunks';

const Layout = () => {
  const username = useSelector(selectLoggedUserUserName);
  const profiles = useSelector(selectLoggedUserProfile);
  const location = useLocation();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const navigate = useNavigate();

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertColor, setAlertColor] = useState('danger');

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    repeatNewPassword: '',
  });

  useEffect(() => {
    if (!token) {
      navigate('/');
    }
  }, [token, navigate]);

  const moduleToPath = {
    'Perfiles': '/home/profiles',
    'Usuarios': '/home/users',
    'Clientes': '/home/clients',
    'Proveedor': '/home/providers',
    'Productos': '/home/products',
    'Categorías': '/home/categories'
  };

  const allowedLinks = profiles
    ? profiles.flatMap(profile => profile.accesses.map(access => access.name))
    : [];

  useEffect(() => {
    const currentPathAccess = Object.values(moduleToPath).find(path => location.pathname.startsWith(path));
    if (currentPathAccess && !allowedLinks.includes(`view_${currentPathAccess.slice(6).toLowerCase()}`)) {
      navigate('/home');
    }
  }, [allowedLinks, location.pathname, navigate, moduleToPath]);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();

    const { oldPassword, newPassword, repeatNewPassword } = formData;

    // Validate if new passwords match
    if (newPassword !== repeatNewPassword) {
      setAlertMessage('Las contraseñas nuevas no coinciden.');
      setShowAlert(true);
      return;
    }

    try {
      // Dispatch the wrapped action
      const response = await dispatch(
        changePasswordWithAuth({
          current_password: oldPassword,
          new_password: newPassword,
        })
      );

      // Handle response
      if (response.status === 200) {
        setAlertMessage('Contraseña actualizada exitosamente.');
        setAlertColor('success')
        setShowAlert(true);
      } else {
        setAlertMessage(response.data.message || 'Error al cambiar la contraseña.');
        setAlertColor('danger')
        setShowAlert(true);
      }
    } catch (error) {
      console.error('Error al cambiar la contraseña:', error);
      setAlertMessage('Conexión no disponible.');
      setShowAlert(true);
    }
  };

  return (
    <Container fluid className="d-flex flex-column vh-100">
      <Row className="flex-grow-1">
        <Col xs={2} id="sidebar-wrapper" className="sidebar d-flex flex-column">
          <div className='pt-4 pb-2 text-center'>
            <img src={img_logo} alt='Logo Metzer' style={{ width: '60%', height: '100%', objectFit: 'cover' }} />
          </div>
          <hr style={{ color: '#f8f9fa', backgroundColor: '#f8f9fa', height: 3 }} />
          <Nav className="col-md-12 d-none d-md-block flex-column">
            <div className="sidebar-sticky"></div>
            <Nav.Item>
              <Card
                className={location.pathname === '/home' ? 'custom-card-active' : 'custom-card'}
              >
                <Nav.Link
                  className={location.pathname === '/home' ? 'custom-link-active' : 'custom-link'}
                  as={Link} to="/home">Home</Nav.Link>
              </Card>
            </Nav.Item>
            {Object.keys(moduleToPath).map((moduleName, index) => {
              return (allowedLinks.includes(`view_${moduleToPath[moduleName].slice(6).toLowerCase()}`) && (
                <Nav.Item key={index}>
                  <Card className={location.pathname === moduleToPath[moduleName] ? 'custom-card-active' : 'custom-card'}>
                    <Nav.Link
                      className={location.pathname === moduleToPath[moduleName] ? 'custom-link-active' : 'custom-link'}
                      as={Link} to={moduleToPath[moduleName]}>{moduleName}</Nav.Link>
                  </Card>
                </Nav.Item>
              ))
            }
            )}
          </Nav>
          <Container className="mt-auto pb-4">
            <Row className='align-items-center'>
              <Col sm='3' md='3' lg='3'>
                <PersonCircle size={35} className="me-3" />
              </Col>
              <Col sm='6' md='6' lg='6'>
                <div>
                  <Card.Text className="mb-2"><strong>{username}</strong></Card.Text>
                </div>
              </Col>
            </Row>
            <Button size='sm' onClick={handleShowModal}>Cambiar contraseña</Button>
            <Button size='sm' onClick={() => dispatch(clearUserLogged())}>Cerrar sesión</Button>
          </Container>
        </Col>
        <Col xs={10} id="page-content-wrapper" className='pt-2'>
          <Outlet />
        </Col>
      </Row>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title>Cambiar Contraseña</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-modal-body">
          <Form onSubmit={handlePasswordChange}>
            <Form.Group controlId="oldPassword" className="mb-3">
              <Form.Label>Contraseña actual</Form.Label>
              <Form.Control
                type="password"
                name="oldPassword"
                value={formData.oldPassword}
                onChange={handleFormChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="newPassword" className="mb-3">
              <Form.Label>Nueva contraseña</Form.Label>
              <Form.Control
                type="password"
                name="newPassword"
                value={formData.newPassword}
                onChange={handleFormChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="repeatNewPassword" className="mb-3">
              <Form.Label>Repetir nueva contraseña</Form.Label>
              <Form.Control
                type="password"
                name="repeatNewPassword"
                value={formData.repeatNewPassword}
                onChange={handleFormChange}
                required
              />
            </Form.Group>
            {showAlert && (
              <Alert
                variant={alertColor}
                onClose={() => setShowAlert(false)}
                className="mt-3 w-100"
              >
                {alertMessage}
              </Alert>
            )}
            <Button variant="dark" type="submit" className="w-100">
              Cambiar contraseña
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Layout;
