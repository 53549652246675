export const getCategoriesWithFilters = async (token, parameter) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/categories/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ parameter })
        }
        );

        if (!response.ok) {
            throw new Error(`Failed operation: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const getCategoryById = async (token, category_uuid) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/categories/${category_uuid}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        );

        if (!response.ok) {
            throw new Error(`Failed operation: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const deleteCategoryById = async (token, category_uuid) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/categories/${category_uuid}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        );

        if (!response.ok) {
            throw new Error(`Failed operation: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};


export const editCategoryById = async (token, category_uuid, form_data) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/categories/${category_uuid}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(form_data)
        }
        );

        if (!response.ok) {
            throw new Error(`Failed operation: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const createCategory = async (token, form_data) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/categories/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(form_data)
        }
        );

        if (!response.ok) {
            throw new Error(`Failed operation: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};