import React, { useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { deleteProvider } from '../../redux/reducers/providersReducer';

const DeleteProviderModal = ({ showDeleteModal, handleClose, handleSearch, provider }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleDelete = () => {
        setLoading(true);
        dispatch(deleteProvider(provider.uuid)).finally(() => {
            setLoading(false);
            handleClose();
            handleSearch();
        });
    };

    return (
        <>
            {provider && (
                <Modal show={showDeleteModal} onHide={handleClose} size='md'>
                    <Modal.Header closeButton>
                        <Modal.Title>¿Desea borrar el proveedor {provider.name}?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleDelete} disabled={loading}>
                            {loading ? <Spinner animation="border" size="sm" /> : 'Borrar'}
                        </Button>
                        <Button variant="outline-primary" onClick={handleClose} disabled={loading}>
                            Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal >
            )}
        </>
    );
}

export default DeleteProviderModal;