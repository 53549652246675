import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAccesses, selectAccesses, selectAccessesError, selectAccessesLoading } from '../../redux/reducers/accessesReducer';
import { editCloseMessage, editProfile, fetchProfiles } from '../../redux/reducers/profileReducer';

const EditProfileModal = ({ showEditModal, handleClose, profile }) => {
    // Llamadas a API y Redux
    const dispatch = useDispatch();

    const loadingAccesses = useSelector(selectAccessesLoading);
    const accesses = useSelector(selectAccesses);
    const errorAccesses = useSelector(selectAccessesError);

    //Mensaje de exito o error
    const successMessage = useSelector(state => state.profile.successMessage);
    const errorMessage = useSelector(state => state.profile.errorMessage);

    const handleCloseMessage = () => {
        dispatch(fetchProfiles(''));
        handleClose();
        dispatch(editCloseMessage());
    }

    //Accesos
    useEffect(() => {
        dispatch(fetchAccesses());
    }, [dispatch]);


    //Formulario
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        uuid_accesses: []
    });

    const handleFormChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    useEffect(() => {
        if (profile) {
            setFormData({
                name: profile.name,
                description: profile.description,
            });
            setInitialFormData({
                name: profile.name,
                description: profile.description,
            });
        }
    }, [profile]);

    //Logica checkboxes
    useEffect(() => {
        if (profile && profile.accesses) {
            setFormData(prevFormData => ({
                ...prevFormData,
                uuid_accesses: profile.accesses.map(access => access.uuid)
            }));
            setInitialFormData(prevFormData => ({
                ...prevFormData,
                uuid_accesses: profile.accesses.map(access => access.uuid)
            }));
        }
    }, [profile]);

    const handleAccessChange = (accessUuid) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            uuid_accesses: prevFormData.uuid_accesses.includes(accessUuid)
                ? prevFormData.uuid_accesses.filter(uuid => uuid !== accessUuid)
                : [...prevFormData.uuid_accesses, accessUuid]
        }));
    };

    //Cambios de formulario
    const [initialFormData, setInitialFormData] = useState();
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        if (initialFormData) {
            const hasChanges = JSON.stringify(initialFormData) !== JSON.stringify(formData);
            setIsChanged(hasChanges);
        }
    }, [formData, initialFormData]);

    //Envio
    const handleSubmit = () => {
        dispatch(editProfile(profile.uuid, formData))
    };

    return (
        <div>
            <Modal show={showEditModal} onHide={handleClose} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Editar perfil</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ marginLeft: '8px' }}>
                    <form style={{ display: 'contents' }}>
                        {profile && (
                            <div>
                                <Row className='pb-2'>
                                    <Col sm='7' md='7' lg='7'>
                                        <InputGroup>
                                            <InputGroup.Text>Nombre:</InputGroup.Text>
                                            <Form.Control
                                                name="name"
                                                type="text"
                                                placeholder="Nombre del perfil..."
                                                value={formData.name}
                                                onChange={handleFormChange}
                                                style={{ marginTop: 0, height: '100%' }}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row className='pb-4'>
                                    <Col sm='7' md='7' lg='7'>
                                        <InputGroup>
                                            <InputGroup.Text>Descripción:</InputGroup.Text>
                                            <Form.Control
                                                name="description"
                                                type="text"
                                                placeholder="Descripción del perfil..."
                                                value={formData.description}
                                                onChange={handleFormChange}
                                                style={{ marginTop: 0, height: '100%' }}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </div>
                        )}
                        <h4 className='pb-4'>Permisos y accesos</h4>
                        {loadingAccesses ? (
                            <p>Cargando accesos...</p>
                        ) : errorAccesses ? (
                            <p>Error: {errorAccesses}</p>
                        ) : accesses.length === 0 ? (
                            <p>No se encontraron accesos</p>
                        ) : (
                            <>
                                {accesses
                                    .map(access => (
                                        <Form.Check
                                            key={access.uuid}
                                            type="checkbox"
                                            id={access.uuid}
                                            label={access.description}
                                            checked={formData.uuid_accesses && formData.uuid_accesses.includes(access.uuid)}
                                            onChange={() => handleAccessChange(access.uuid)}
                                        />
                                    ))}
                            </>
                        )}
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    {!isChanged && (
                        <p className="form-text text-muted">
                            Debe realizar cambios para guardar
                        </p>
                    )}
                    <Button variant="primary" style={{ marginTop: '0px' }} onClick={handleSubmit} disabled={!isChanged}>Guardar cambios</Button>
                </Modal.Footer>
                <Modal show={!!successMessage || !!errorMessage} onHide={handleCloseMessage} style={{ borderColor: '#FFFFFF' }}>
                    <Modal.Header closeButton>
                        <Modal.Title>{successMessage ? 'Modificación exitosa' : 'Error en la modificación'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {successMessage || errorMessage}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseMessage}>Cerrar</Button>
                    </Modal.Footer>
                </Modal>
            </Modal >


        </div>
    );
}

export default EditProfileModal;