import React, { useEffect, useState } from 'react'
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { createUser, editCloseMessage, selectErrorMessage, selectSuccessMessage } from '../../redux/reducers/usersReducer';
import { fetchProfiles, selectProfiles } from '../../redux/reducers/profileReducer';

const NewUserModal = ({ showNewUserModal, handleClose }) => {
    // Llamadas a API y Redux
    const dispatch = useDispatch();

    //Mensaje de exito o error
    const successMessage = useSelector(selectSuccessMessage);
    const errorMessage = useSelector(selectErrorMessage);

    //Formulario
    const [formData, setFormData] = useState({
        name: '',
        password: '',
        email: '',
        profiles: [],
    });
    const [showPassword, setShowPassword] = useState(false);

    const handleFormChange = (event) => {
        const { name, value } = event.target;
        //Telefono, dni pueden ser nada mas numeros
        if (name === 'phone' || name === 'dni') value.replace(/\D/g, '')
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    //Perfiles
    const profiles = useSelector(selectProfiles);
    useEffect(() => {
        dispatch(fetchProfiles(null));
    }, [dispatch]);

    const chunkArray = (arr, chunkSize) => {
        const arrCopy = [...arr];
        arrCopy.sort((a, b) => a.name.localeCompare(b.name));

        const chunks = [];
        for (let i = 0; i < arrCopy.length; i += chunkSize) {
            chunks.push(arrCopy.slice(i, i + chunkSize));
        }
        return chunks;
    };

    const chunkedProfiles = chunkArray(profiles, 4);

    const handleProfileCheckboxChange = (event) => {
        const { id, checked } = event.target;
        if (checked) {
            setFormData(prevFormData => ({
                ...prevFormData,
                profiles: [...prevFormData.profiles, id]
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                profiles: prevFormData.profiles.filter(profileId => profileId !== id)
            }));
        }
    };

    const handleCloseMessage = () => {
        dispatch(editCloseMessage());
        if (successMessage) {
            setFormData({
                name: '',
                password: '',
                email: '',
                profiles: []
            })
            handleClose();
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(createUser(formData))
    };

    return (
        <div>
            <Modal show={showNewUserModal} onHide={handleClose} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title><strong>Nuevo usuario</strong></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ marginLeft: '8px', marginRight: '8px' }}>
                    <form style={{ display: 'contents' }} onSubmit={handleSubmit}>
                        <div>
                            <Row className='pb-1'>
                                <Col sm='4' md='4' lg='4'>
                                    <Form.Control
                                        name='name'
                                        type="text"
                                        placeholder="Nombre y apellido..."
                                        value={formData.name}
                                        onChange={handleFormChange}
                                        style={{ marginTop: 0, height: '100%' }}
                                        required
                                    />
                                </Col>
                                <Col sm='4' md='4' lg='4'>
                                    <Form.Control
                                        name='email'
                                        type="text"
                                        placeholder="Email..."
                                        value={formData.email}
                                        onChange={handleFormChange}
                                        style={{ marginTop: 0, height: '100%' }}
                                        required
                                    />
                                </Col>
                                <Col sm='4' md='4' lg='4'>
                                    <InputGroup style={{ height: '100%' }}>
                                        <Form.Control
                                            type={showPassword ? "text" : "password"}
                                            name='password'
                                            placeholder="Contraseña..."
                                            value={formData.password}
                                            onChange={handleFormChange}
                                            style={{ flex: '1', height: '100%', borderTopRightRadius: 0, borderBottomRightRadius: 0, marginTop: 0 }}
                                            required
                                        />
                                        <Button
                                            variant="secondary"
                                            onClick={togglePasswordVisibility}
                                            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, height: '100%', width: '60px' }}
                                        >
                                            {showPassword ? <EyeFill size={'25'} /> : <EyeSlashFill size={'25'} />}
                                        </Button>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <>
                                <h5 className='pt-4 pb-2'>Perfiles del usuario</h5>
                                {chunkedProfiles.map((chunk, index) => (
                                    <Row key={index} className="mb-3" style={{ marginLeft: '1rem' }}>
                                        {chunk.map(profile => (
                                            <Col key={profile.uuid} md={3} className="pb-3">
                                                <Form.Check
                                                    type="checkbox"
                                                    id={profile.uuid}
                                                    label={profile.name}
                                                    onChange={handleProfileCheckboxChange}
                                                    checked={formData.profiles.includes(profile.uuid)}
                                                />
                                            </Col>
                                        ))}
                                    </Row>
                                ))}
                            </>
                        </div>
                        <Modal.Footer>
                            <Button variant="primary" type='submit'>Crear usuario</Button>
                        </Modal.Footer>
                    </form>
                </Modal.Body>
                <Modal show={!!successMessage || !!errorMessage} onHide={handleCloseMessage}>
                    <Modal.Header closeButton>
                        <Modal.Title>{errorMessage ? 'Error en la creación' : 'Creación exitosa'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {successMessage || errorMessage}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseMessage}>Cerrar</Button>
                    </Modal.Footer>
                </Modal>
            </Modal >


        </div>
    );
}

export default NewUserModal