import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { deleteUser } from '../../redux/reducers/usersReducer';

const DeleteUserModal = ({ showDeleteModal, handleClose, user }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleDelete = () => {
        setLoading(true);
        dispatch(deleteUser(user.user.uuid)).finally(() => {
            setLoading(false);
            handleClose();
        });
    };

    return (
        <>
            {user && (
                <Modal show={showDeleteModal} onHide={handleClose} size='md'>
                    <Modal.Header closeButton>
                        <Modal.Title><strong>¿Desea borrar el usuario {user.name}?</strong></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Se borrará el usuario con todos los accesos asociados.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleDelete} disabled={loading}>
                            {loading ? <Spinner animation="border" size="sm" /> : 'Borrar'}
                        </Button>
                        <Button variant="outline-primary" onClick={handleClose} disabled={loading}>
                            Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal >
            )}
        </>
    );
}

export default DeleteUserModal