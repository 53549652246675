import { createSlice } from '@reduxjs/toolkit';
import { withAuthentication } from '../middlewares';
import { createClient, deleteClientById, editClientById, getClientById, getClientsWithSearch } from '../actions/clientsThunks';

const initialState = {
    data: [],
    client: null,
    loading: false,
    error: null,
    successMessage: null,
    errorMessage: null,
};

const clientsSlice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
        //Traer todos los perfiles
        fetchClientsStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchClientsSuccess(state, action) {
            state.loading = false;
            state.data = action.payload;
        },
        fetchClientsFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        //Traer un perfil para edición/detalle
        fetchClientStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchClientSuccess(state, action) {
            state.loading = false;
            state.client = action.payload;
        },
        fetchClientFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        clearClient(state) {
            state.client = null;
        },
        //Editar un perfil
        editClientStart(state) {
            state.loading = true;
            state.error = null;
        },
        editClientSuccess(state, action) {
            state.loading = false;
            state.client = action.payload;
            state.successMessage = 'Operación exitosa';
            state.errorMessage = null;
        },
        editClientFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.errorMessage = 'Error en la operación';
            state.successMessage = null;
        },
        editCloseMessage(state) {
            state.successMessage = null;
            state.errorMessage = null;
        },
        //Borrar un perfil
        deleteClientStart(state) {
            state.loading = true;
            state.error = null;
        },
        deleteClientSuccess(state, action) {
            state.loading = false;
            state.error = null;

            const clientuuid = action.payload;
            const deletedClientIndex = state.data.findIndex(client => client.uuid === clientuuid);

            if (deletedClientIndex !== -1) {
                state.data.splice(deletedClientIndex, 1);
                if (state.client && state.client.uuid === clientuuid) {
                    state.client = null;
                }
            }
        },
        deleteClientFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        //Crear perfil
        createNewClientStart(state) {
            state.loading = true;
            state.error = null;
        },
        createNewClientSuccess(state, action) {
            state.loading = false;
            state.client = action.payload;
            state.successMessage = 'Cliente creado exitosamente';
            state.errorMessage = null;
        },
        createNewClientFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.errorMessage = 'Error al crear cliente';
            state.successMessage = null;
        },
    },
});

export const fetchClients = withAuthentication((authToken, ...args) => async (dispatch, getState) => {
    dispatch(fetchClientsStart());
    try {
        const response = await getClientsWithSearch(authToken, ...args);
        const clients = response.clients || [];
        dispatch(fetchClientsSuccess(clients));
    } catch (error) {
        dispatch(fetchClientsFailure(error.message));
    }
});

export const fetchClientById = withAuthentication((authToken, clientuuid) => async (dispatch, getState) => {
    dispatch(fetchClientStart());
    try {
        const client = await getClientById(authToken, clientuuid);
        dispatch(fetchClientSuccess(client));
    } catch (error) {
        dispatch(fetchClientFailure(error.message));
    }
});

export const createNewClient = withAuthentication((authToken, newClient) => async (dispatch, getState) => {
    dispatch(createNewClientStart());
    try {
        const response = await createClient(authToken, newClient);
        const client = response.client;
        dispatch(createNewClientSuccess(client));
    } catch (error) {
        dispatch(createNewClientFailure(error.message));
    }
});

export const editClient = withAuthentication((authToken, clientuuid, editedClient) => async (dispatch, getState) => {
    dispatch(editClientStart());
    try {
        const response = await editClientById(authToken, clientuuid, editedClient);
        const client = response.client || {};
        dispatch(editClientSuccess(client));
    } catch (error) {
        dispatch(editClientFailure(error.message));
    }
});

export const deleteClient = withAuthentication((authToken, clientuuid) => async (dispatch, getState) => {
    dispatch(deleteClientStart());
    try {
        const response = await deleteClientById(authToken, clientuuid);
        const clientuuid = response.clientuuid || clientuuid;
        dispatch(deleteClientSuccess(clientuuid));
    } catch (error) {
        dispatch(deleteClientFailure(error.message));
    }
});

export const {
    fetchClientsStart,
    fetchClientsSuccess,
    fetchClientsFailure,
    fetchClientStart,
    fetchClientSuccess,
    fetchClientFailure,
    clearClient,
    editClientStart,
    editClientSuccess,
    editClientFailure,
    editCloseMessage,
    deleteClientStart,
    deleteClientSuccess,
    deleteClientFailure,
    createNewClientStart,
    createNewClientSuccess,
    createNewClientFailure,
} = clientsSlice.actions;

export const selectClients = (state) => state.clients.data;
export const selectClientById = (state) => state.clients.client;
export const selectClientsLoading = (state) => state.clients.loading;
export const selectClientsError = (state) => state.clients.error;
export const selectClientsSuccessMessage = (state) => state.clients.successMessage;
export const selectClientsErrorMessage = (state) => state.clients.errorMessage;

export default clientsSlice.reducer;
