import { createSlice } from '@reduxjs/toolkit';
import { withAuthentication } from '../middlewares';
import { createProvider, deleteProviderById, editProviderById, getProviderById, getProvidersWithFilters } from '../actions/providersThunks';

const initialState = {
    data: [],
    provider: null,
    loading: false,
    error: null,
    successMessage: null,
    errorMessage: null,
};

const providersSlice = createSlice({
    name: 'providers',
    initialState,
    reducers: {
        //Traer todos los perfiles
        fetchProvidersStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchProvidersSuccess(state, action) {
            state.loading = false;
            state.data = action.payload;
        },
        fetchProvidersFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        //Traer un perfil para edición/detalle
        fetchProviderStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchProviderSuccess(state, action) {
            state.loading = false;
            state.provider = action.payload;
        },
        fetchProviderFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        clearProvider(state) {
            state.provider = null;
        },
        //Editar un perfil
        editProviderStart(state) {
            state.loading = true;
            state.error = null;
        },
        editProviderSuccess(state, action) {
            state.loading = false;
            state.provider = action.payload;
            state.successMessage = 'Operación exitosa';
            state.errorMessage = null;
        },
        editProviderFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.errorMessage = 'Error en la operación';
            state.successMessage = null;
        },
        editCloseMessage(state) {
            state.successMessage = null;
            state.errorMessage = null;
        },
        //Borrar un perfil
        deleteProviderStart(state) {
            state.loading = true;
            state.error = null;
        },
        deleteProviderSuccess(state, action) {
            state.loading = false;
            state.error = null;

            const provideruuid = action.payload;
            const deletedProviderIndex = state.data.findIndex(provider => provider.uuid === provideruuid);

            if (deletedProviderIndex !== -1) {
                state.data.splice(deletedProviderIndex, 1);
                if (state.provider && state.provider.uuid === provideruuid) {
                    state.provider = null;
                }
            }
        },
        deleteProviderFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        //Crear perfil
        createNewProviderStart(state) {
            state.loading = true;
            state.error = null;
        },
        createNewProviderSuccess(state, action) {
            state.loading = false;
            state.provider = action.payload;
            state.successMessage = 'Proveedor creado exitosamente';
            state.errorMessage = null;
        },
        createNewProviderFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.errorMessage = 'Error al crear proveedor';
            state.successMessage = null;
        },
    },
});

export const fetchProviders = withAuthentication((authToken, ...args) => async (dispatch, getState) => {
    dispatch(fetchProvidersStart());
    try {
        const response = await getProvidersWithFilters(authToken, ...args);
        const providers = response.providers || [];
        dispatch(fetchProvidersSuccess(providers));
    } catch (error) {
        dispatch(fetchProvidersFailure(error.message));
    }
});

export const fetchProviderById = withAuthentication((authToken, provideruuid) => async (dispatch, getState) => {
    dispatch(fetchProviderStart());
    try {
        const provider = await getProviderById(authToken, provideruuid);
        dispatch(fetchProviderSuccess(provider));
    } catch (error) {
        dispatch(fetchProviderFailure(error.message));
    }
});

export const createNewProvider = withAuthentication((authToken, newProvider) => async (dispatch, getState) => {
    dispatch(createNewProviderStart());
    try {
        const response = await createProvider(authToken, newProvider);
        const provider = response.provider;
        dispatch(createNewProviderSuccess(provider));
    } catch (error) {
        dispatch(createNewProviderFailure(error.message));
    }
});

export const editProvider = withAuthentication((authToken, provideruuid, editedProvider) => async (dispatch, getState) => {
    dispatch(editProviderStart());
    try {
        const response = await editProviderById(authToken, provideruuid, editedProvider);
        const provider = response.provider || {};
        dispatch(editProviderSuccess(provider));
    } catch (error) {
        dispatch(editProviderFailure(error.message));
    }
});

export const deleteProvider = withAuthentication((authToken, provideruuid) => async (dispatch, getState) => {
    dispatch(deleteProviderStart());
    try {
        const response = await deleteProviderById(authToken, provideruuid);
        dispatch(deleteProviderSuccess(response.provideruuid));
    } catch (error) {
        dispatch(deleteProviderFailure(error.message));
    }
});

export const {
    fetchProvidersStart,
    fetchProvidersSuccess,
    fetchProvidersFailure,
    fetchProviderStart,
    fetchProviderSuccess,
    fetchProviderFailure,
    clearProvider,
    editProviderStart,
    editProviderSuccess,
    editProviderFailure,
    editCloseMessage,
    deleteProviderStart,
    deleteProviderSuccess,
    deleteProviderFailure,
    createNewProviderStart,
    createNewProviderSuccess,
    createNewProviderFailure,
} = providersSlice.actions;

export const selectProviders = (state) => state.providers.data;
export const selectProviderById = (state) => state.providers.provider;
export const selectProvidersLoading = (state) => state.providers.loading;
export const selectProvidersError = (state) => state.providers.error;
export const selectProvidersSuccessMessage = (state) => state.providers.successMessage;
export const selectProvidersErrorMessage = (state) => state.providers.errorMessage;

export default providersSlice.reducer;
