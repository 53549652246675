import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAccesses, selectAccesses, selectAccessesError, selectAccessesLoading } from '../../redux/reducers/accessesReducer';
import { createNewProfile, editCloseMessage } from '../../redux/reducers/profileReducer';

const NewProfileModal = ({ showNewProfileModal, handleClose, handleSearch }) => {
    // Llamadas a API y Redux
    const dispatch = useDispatch();

    const loadingAccesses = useSelector(selectAccessesLoading);
    const accesses = useSelector(selectAccesses);
    const errorAccesses = useSelector(selectAccessesError);

    //Mensaje de exito o error
    const successMessage = useSelector(state => state.profile.successMessage);
    const errorMessage = useSelector(state => state.profile.errorMessage);

    const handleCloseMessage = () => {
        handleClose();
        setName('')
        setCheckedAccesses([])
        setDescription("")
        dispatch(editCloseMessage());
        handleSearch();
    }

    //Accesos
    useEffect(() => {
        dispatch(fetchAccesses());
    }, [dispatch]);

    //Logica checkboxes
    const [checkedAccesses, setCheckedAccesses] = useState([]);

    const handleAccessChange = (accessUuid) => {
        setCheckedAccesses(prevAccesses => {
            if (prevAccesses.includes(accessUuid)) {
                return prevAccesses.filter(uuid => uuid !== accessUuid);
            } else {
                return [...prevAccesses, accessUuid];
            }
        });
    };

    //Nombre y descripcion
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(createNewProfile(JSON.stringify({
            name: name,
            description: description,
            uuid_accesses: checkedAccesses
        })));
    };

    return (
        <div>
            <Modal show={showNewProfileModal} onHide={handleClose} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Nuevo perfil</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ marginLeft: '8px' }}>
                    <form style={{ display: 'contents' }} onSubmit={handleSubmit}>
                        <div>
                            <Row className='pb-2'>
                                <Col sm='7' md='7' lg='7'>
                                    <InputGroup>
                                        <InputGroup.Text>Nombre:</InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            placeholder="Nombre del perfil..."
                                            value={name}
                                            onChange={handleNameChange}
                                            style={{ marginTop: 0, height: '100%' }}
                                            required
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row className='pb-4'>
                                <Col sm='7' md='7' lg='7'>
                                    <InputGroup>
                                        <InputGroup.Text>Descripción:</InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            placeholder="Descripción del perfil..."
                                            value={description}
                                            onChange={handleDescriptionChange}
                                            style={{ marginTop: 0, height: '100%' }}
                                            required
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                        </div>
                        <h4 className='pb-4'>Permisos y accesos</h4>

                        {loadingAccesses ? (
                            <p>Cargando accesos...</p>
                        ) : errorAccesses ? (
                            <p>Error: {errorAccesses}</p>
                        ) : accesses.length === 0 ? (
                            <p>No se encontraron accesos</p>
                        ) : (
                            <>
                                {accesses
                                    .filter(
                                        access =>
                                        (access.name.startsWith('view_') ||
                                            access.name.startsWith('manage_'))
                                    )
                                    .sort((a, b) =>
                                        a.name.startsWith('view_')
                                            ? -1
                                            : b.name.startsWith('view_')
                                                ? 1
                                                : 0
                                    )
                                    .map(access => (
                                        <Form.Check
                                            key={access.uuid}
                                            type="checkbox"
                                            id={access.uuid}
                                            label={access.description}
                                            checked={checkedAccesses.includes(access.uuid)}
                                            onChange={() => handleAccessChange(access.uuid)}
                                        />
                                    ))}
                            </>
                        )}
                        <Modal.Footer>
                            <Button variant="primary" type='submit'>Crear perfil</Button>
                        </Modal.Footer>
                    </form>
                </Modal.Body>
                <Modal show={!!successMessage || !!errorMessage} onHide={handleCloseMessage}>
                    <Modal.Header closeButton>
                        <Modal.Title>{errorMessage ? 'Error en la creación' : 'Creación exitosa'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {successMessage || errorMessage}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseMessage}>Cerrar</Button>
                    </Modal.Footer>
                </Modal>
            </Modal >


        </div>
    );
}

export default NewProfileModal;