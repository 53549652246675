import { withAuthentication } from "../middlewares";

export const login = (email, password) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        return {
          data: errorData,
          status: response.status,
        };
      }

      const data = await response.json();

      return {
        data: data,
        status: response.status,
      };
    } catch (error) {
      console.error('Login error:', error);
      return {
        data: { message: 'Conexión no disponible' },
        status: 500,
      };
    }
  };
};

const changePassword = (token, formData) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/change_password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        return {
          data: errorData,
          status: response.status,
        };
      }

      const data = await response.json();

      return {
        data: data,
        status: response.status,
      };
    } catch (error) {
      console.error('Login error:', error);
      return {
        data: { message: 'Conexión no disponible' },
        status: 500,
      };
    }
  };
};

export const changePasswordWithAuth = withAuthentication(changePassword);

export const forgotPassword = (email) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/forgot_password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        return {
          data: errorData,
          status: response.status,
        };
      }

      const data = await response.json();

      return {
        data: data,
        status: response.status,
      };
    } catch (error) {
      console.error('Login error:', error);
      return {
        data: { message: 'Conexión no disponible' },
        status: 500,
      };
    }
  };
};