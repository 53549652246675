import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Home from './pages/Home'; // Asegúrate de crear este componente
import Layout from './pages/Layout'; // Asume que tienes un Layout que incluye navbar y sidebar
import Users from './pages/Users/Users';
import Profiles from './pages/Profiles/Profiles';
import Clients from './pages/Clients/Clients';
import Providers from './pages/Providers/Providers';
import Products from './pages/Products/Products';
import Categories from './pages/Categories/Categories';
// import './global.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='users' element={<Users />} />
          <Route path='profiles' element={<Profiles />} />
          <Route path='clients' element={<Clients />} />
          <Route path='providers' element={<Providers />} />
          <Route path='products' element={<Products />} />
          <Route path='categories' element={<Categories />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
