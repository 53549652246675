import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, ListGroup, Button, ButtonGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories, selectCategories, selectCategoriesError, selectCategoriesLoading, selectCategoryById, clearCategory, fetchCategoryById } from '../../redux/reducers/categoriesReducer'
import { selectLoggedUserProfile } from '../../redux/reducers/authReducer';
import { PencilSquare, Trash } from 'react-bootstrap-icons';
import SearchBar from '../../components/Searchbar';
import '../styles/Categories.css'
import NewCategoryModal from './NewCategoryModal';
import EditCategoryModal from './EditCategoryModal';
import DeleteCategoryModal from './DeleteCategoryModal'

const Categories = () => {
    // Llamadas a API y Redux
    const dispatch = useDispatch();
    const loading = useSelector(selectCategoriesLoading);
    const categories = useSelector(selectCategories);
    const error = useSelector(selectCategoriesError);
    const category = useSelector(selectCategoryById);
    const profilesAuth = useSelector(selectLoggedUserProfile);

    useEffect(() => {
        dispatch(fetchCategories(null));
    }, [dispatch]);

    const hasManageCategoriesAccess = profilesAuth?.some(profile =>
        profile.accesses?.some(access => access.name === 'manage_categories')
    );

    //Popups
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showNewEntityModal, setShowNewEntityModal] = useState(false);

    const handleEditOpen = (category_uuid) => {
        dispatch(fetchCategoryById(category_uuid));
        setShowEditModal(true);
    };

    const handleEditClose = () => {
        dispatch(clearCategory());
        setShowEditModal(false);
    };

    const handleDeleteOpen = (category_uuid) => {
        dispatch(fetchCategoryById(category_uuid));
        setShowDeleteModal(true);
    };

    const handleDeleteClose = (category_uuid) => {
        dispatch(clearCategory());
        setShowDeleteModal(false);
    };

    const handleNewEntityOpen = () => {
        setShowNewEntityModal(true);
    };

    const handleNewEntityClose = () => {
        setShowNewEntityModal(false);
    };

    //Searchbar
    const handleSearch = (text) => {
        dispatch(fetchCategories(text));
    };

    return (
        <Container className='pt-4 pb-4'>
            <Row>
                <Col>
                    <Container className='pb-4 align-items-center'>
                        <Row className="align-items-center">
                            <Col sm='3' md='3' lg='3'>
                                <h1 style={{ marginBottom: 0 }}>Categorías</h1>
                            </Col>
                            <Col sm='7' md='7' lg='7'>
                                <SearchBar onSearch={handleSearch} parameterName={"nombre"} />
                            </Col>
                            {hasManageCategoriesAccess && (
                                <Col sm='2' md='2' lg='2'>
                                    <Button variant='primary' style={{ marginTop: 0, padding: '0.8rem 1rem', marginLeft: '10px', backgroundColor: '#0d6efd', borderColor: '#0d6efd' }} onClick={handleNewEntityOpen}>Nueva categoría</Button>
                                </Col>)}
                        </Row>
                    </Container>
                    {loading ? (
                        <p>Cargando...</p>
                    ) : error ? (
                        <p>Error: {error}</p>
                    ) : categories.length === 0 ? (
                        <p>No se encontraron categorias.</p>
                    ) : (
                        <>
                            <ListGroup>
                                {categories.map(category => (
                                    <ListGroup.Item key={category.uuid} className="d-flex justify-content-between align-items-center mb-3" style={{ backgroundColor: '#36454F', border: '0' }}>
                                        <div className="d-flex align-items-center" style={{ width: '100%' }}>
                                            <div style={{ width: '35%' }}>
                                                <Card.Text className="mb-2"><strong>{category.name}</strong></Card.Text>
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <ButtonGroup>
                                                <Button variant="danger" style={{ backgroundColor: '#0d6efd', borderColor: '#0d6efd' }} size="lg" onClick={() => handleEditOpen(category.uuid)}><PencilSquare /></Button>
                                                <Button variant="danger" size="lg" onClick={() => handleDeleteOpen(category.uuid)}><Trash /></Button>
                                            </ButtonGroup>
                                        </div>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </>
                    )}


                    {/*POPUPS*/}
                    <NewCategoryModal showNewEntityModal={showNewEntityModal} handleClose={handleNewEntityClose} handleSearch={handleSearch} />
                    <EditCategoryModal showEditModal={showEditModal} handleClose={handleEditClose} category={category} handleSearch={handleSearch} />
                    <DeleteCategoryModal showDeleteModal={showDeleteModal} handleClose={handleDeleteClose} category={category} handleSearch={handleSearch} />
                </Col>
            </Row>
        </Container >
    );
}

export default Categories