import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { editProduct, editCloseMessage, selectProductsErrorMessage, selectProductsSuccessMessage } from '../../redux/reducers/productsReducer';
import { selectCategories, selectCategoriesLoading, selectCategoriesError } from '../../redux/reducers/categoriesReducer'

const EditProductModal = ({ showEditModal, handleClose, handleSearch, product }) => {
    // Llamadas a API y Redux
    const dispatch = useDispatch();

    //Categorias
    const categories = useSelector(selectCategories);
    const categoriesLoading = useSelector(selectCategoriesLoading);
    const categoriesError = useSelector(selectCategoriesError);

    //Mensaje de exito o error
    const successMessage = useSelector(selectProductsSuccessMessage);
    const errorMessage = useSelector(selectProductsErrorMessage);

    const handleCloseMessage = () => {
        handleClose();
        setFormData({
            code: '',
            description: '',
            unit_type: 'cm',
            uuid_category: ''
        })
        dispatch(editCloseMessage());
        handleSearch();
    }

    const unitTypes = [
        // Length/Distance
        "cm", "m", "km", "in", "ft",

        // Area
        "cm²", "m²", "ha",

        // Volume
        "L", "cm³", "m³", "ft³", "gal", "AF",

        // Flow Rate
        "GPM", "L/min", "m³/h", "L/h",

        // Pressure
        "Psi", "Bar"
    ];

    //FormData
    const [formData, setFormData] = useState({
        code: '',
        description: '',
        unit_type: 'cm',
        uuid_category: ''
    });

    useEffect(() => {
        if (product) {
            setFormData({
                code: product.code,
                description: product.description,
                unit_type: product.unit_type,
                uuid_category: product.uuid_category
            });
            setInitialFormData({
                code: product.code,
                description: product.description,
                unit_type: product.unit_type,
                uuid_category: product.uuid_category
            });
        }
    }, [product]);

    const handleFormChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleUnitTypeChange = (event) => {
        setFormData({ ...formData, unit_type: event.target.value });
    };

    const handleCategoryChange = (event) => {
        setFormData({ ...formData, uuid_category: event.target.value });
    };

    //Cambios de formulario
    const [initialFormData, setInitialFormData] = useState();
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        if (initialFormData) {
            const hasChanges = JSON.stringify(initialFormData) !== JSON.stringify(formData);
            setIsChanged(hasChanges);
        }
    }, [formData, initialFormData]);

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(editProduct(product.uuid, formData));
    };

    return (
        <div>
            <Modal show={showEditModal} onHide={handleClose} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Editar producto</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ marginLeft: '8px' }}>
                    <form style={{ display: 'contents' }} onSubmit={handleSubmit}>
                        <div>
                            <Row className='pb-2'>
                                <Col sm="4" md="4" lg="4">
                                    <InputGroup>
                                        <InputGroup.Text>Código:</InputGroup.Text>
                                        <Form.Control
                                            name="code"
                                            type="text"
                                            placeholder="Código interno..."
                                            value={formData.code}
                                            onChange={handleFormChange}
                                            style={{ marginTop: 0, height: '100%' }}
                                            required
                                        />
                                    </InputGroup>
                                </Col>
                                <Col sm="5" md="5" lg="5">
                                    <InputGroup style={{ height: '100%', }}>
                                        <InputGroup.Text>Categoría:</InputGroup.Text>
                                        <Form.Select
                                            as="select"
                                            value={formData.uuid_category}
                                            onChange={handleCategoryChange}
                                            style={{
                                                marginTop: 0,
                                                height: '100%',
                                                paddingRight: '1.5rem',
                                            }}
                                        >
                                            <option value='' disabled>-- Seleccione categoría --</option>
                                            {categoriesLoading ? (
                                                <option disabled>Cargando...</option>
                                            ) : categoriesError ? (
                                                <option disabled>Error: {categoriesError}</option>
                                            ) : categories.length === 0 ? (
                                                <option disabled>No se encontraron categorias.</option>
                                            ) : (
                                                categories.map((category) => (
                                                    <option key={category.uuid} value={category.uuid}>
                                                        {category.name}
                                                    </option>
                                                ))
                                            )}
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                                <Col sm="3" md="3" lg="3">
                                    <InputGroup style={{ height: '100%', }}>
                                        <InputGroup.Text>Unidad:</InputGroup.Text>
                                        <Form.Select
                                            as="select"
                                            value={formData.unit_type}
                                            onChange={handleUnitTypeChange}
                                            style={{
                                                marginTop: 0,
                                                height: '100%',
                                                paddingRight: '1.5rem',
                                            }}
                                        >
                                            {unitTypes.map((unitType) => (
                                                <option key={unitType} value={unitType}>{unitType}</option>
                                            ))}
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row className='pb-2'>
                                <InputGroup>
                                    <InputGroup.Text>Descripción:</InputGroup.Text>
                                    <Form.Control
                                        name="description"
                                        type="text"
                                        placeholder="Descripción del producto..."
                                        value={formData.description}
                                        onChange={handleFormChange}
                                        style={{ marginTop: 0, height: '100%' }}
                                        required
                                    />
                                </InputGroup>

                            </Row>
                        </div>
                        <Modal.Footer>
                            <Button variant="primary" type='submit'>Crear producto</Button>
                        </Modal.Footer>
                    </form>
                </Modal.Body>
                <Modal show={!!successMessage || !!errorMessage} onHide={handleCloseMessage}>
                    <Modal.Header closeButton>
                        <Modal.Title>{errorMessage ? 'Error en la modificación' : 'Modificación exitosa'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {successMessage || errorMessage}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseMessage}>Cerrar</Button>
                    </Modal.Footer>
                </Modal>
            </Modal >
        </div>
    );
}

export default EditProductModal;