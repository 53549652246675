import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { forgotPassword, login } from '../redux/actions/authThunks';
import { Container, Modal, Button, Spinner, Form, Alert } from 'react-bootstrap';
import "./styles/Login.css";
import img_logo from "../assets/metzer-logo.png";
import { setUserLogged } from '../redux/reducers/authReducer';

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Forgot Password Modal States
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [forgotPasswordSpinner, setForgotPasswordSpinner] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertColor, setAlertColor] = useState('danger');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSpinner(true);
    const response = await dispatch(login(email, password));
    setSpinner(false);
    if (response.status === 200) {
      await dispatch(setUserLogged(response.data.user));
      navigate('/home');
    } else {
      setErrorMessage(response.data.message || "Nombre de usuario o contraseña incorrecto");
      setShowErrorModal(true);
    }
  };

  const handleClose = () => {
    setShowErrorModal(false);
    setErrorMessage('');
  };

  const handleForgotPassword = () => {
    setShowForgotPasswordModal(true);
  };

  const handleForgotPasswordSubmit = async () => {
    setForgotPasswordSpinner(true);
    try {
      const response = await dispatch(
        forgotPassword(forgotPasswordEmail)
      );

      if (response.status === 200) {
        setAlertMessage('Correo enviado exitosamente.');
        setAlertColor('success')
        setShowAlert(true);
      } else if (response.status === 404) {
        setAlertMessage(response.data.error || 'Usuario no encontrado.');
        setAlertColor('danger')
        setShowAlert(true);
      } else {
        setAlertMessage(response.data.message || 'Error al enviar correo.');
        setAlertColor('danger')
        setShowAlert(true);
      }
      setForgotPasswordSpinner(false);
    } catch (error) {
      setForgotPasswordSpinner(false);
      setAlertColor('danger')
      setAlertMessage('Ocurrió un error al intentar enviar el correo.');
      setShowAlert(true);
    }
  };

  const closeForgotPasswordModal = () => {
    setShowForgotPasswordModal(false);
    setForgotPasswordEmail('');
    setAlertMessage('');
  };

  return (
    <Container className='login-container'>
      <form onSubmit={handleSubmit}>
        <div className='img-logo mt-4'>
          <a href='https://metzer.com.ar/' target="_blank" rel="noopener noreferrer">
            <img src={img_logo} alt='Logo Metzer' />
          </a>
        </div>
        <div className="form-group">
          <label htmlFor="email" className="pb-2">Nombre de Usuario</label>
          <input
            type="text"
            className="form-control"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password" className="pb-2">Contraseña</label>
          <input
            type="password"
            className="form-control"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <p
          style={{ fontSize: '12px', marginBottom: '5px', fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer' }}
          onClick={handleForgotPassword}
        >
          Olvidé mi contraseña
        </p>

        <button type="submit" className="btn btn-primary" disabled={spinner}>
          {spinner ? <Spinner as="span" animation="border" size="sm" /> : 'Iniciar Sesión'}
        </button>
      </form>

      {/* Error Modal */}
      <Modal show={showErrorModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Error de inicio de sesión</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Forgot Password Modal */}
      <Modal show={showForgotPasswordModal} onHide={closeForgotPasswordModal} centered>
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title>Restablecer contraseña</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-modal-body">
          <Form>
            <Form.Group controlId="forgotPasswordEmail">
              <Form.Label>Correo electrónico</Form.Label>
              <Form.Control
                type="email"
                placeholder="Ingresa tu correo electrónico"
                value={forgotPasswordEmail}
                onChange={(e) => setForgotPasswordEmail(e.target.value)}
                required
              />
            </Form.Group>
          </Form>
          {showAlert && (
            <Alert
              variant={alertColor}
              onClose={() => setShowAlert(false)}
              className="mt-3 w-100"
            >
              {alertMessage}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleForgotPasswordSubmit} disabled={forgotPasswordSpinner}>
            {forgotPasswordSpinner ? <Spinner as="span" animation="border" size="sm" /> : 'Enviar'}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Login;
