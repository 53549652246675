import { createSlice } from '@reduxjs/toolkit';
import { deleteProfileById, getProfileById, getProfilesWithSearch, editProfileById, createProfile } from '../actions/profileThunks';
import { withAuthentication } from '../middlewares';

const initialState = {
    data: [],
    profile: null,
    loading: false,
    error: null,
    successMessage: null,
    errorMessage: null,
};

const profilesSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        //Traer todos los perfiles
        fetchProfilesStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchProfilesSuccess(state, action) {
            state.loading = false;
            state.data = action.payload;
        },
        fetchProfilesFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        //Traer un perfil para edición/detalle
        fetchProfileStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchProfileSuccess(state, action) {
            state.loading = false;
            state.profile = action.payload;
        },
        fetchProfileFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        clearProfile(state) {
            state.profile = null;
        },
        //Editar un perfil
        editProfileStart(state) {
            state.loading = true;
            state.error = null;
        },
        editProfileSuccess(state, action) {
            state.loading = false;
            state.profile = action.payload;
            state.successMessage = 'Operación exitosa';
            state.errorMessage = null;
        },
        editProfileFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.errorMessage = 'Error en la operación';
            state.successMessage = null;
        },
        editCloseMessage(state) {
            state.successMessage = null;
            state.errorMessage = null;
        },
        //Borrar un perfil
        deleteProfileStart(state) {
            state.loading = true;
            state.error = null;
        },
        deleteProfileSuccess(state, action) {
            state.loading = false;
            state.error = null;

            const profileuuid = action.payload;
            const deletedProfileIndex = state.data.findIndex(profile => profile.uuid === profileuuid);

            if (deletedProfileIndex !== -1) {
                state.data.splice(deletedProfileIndex, 1);
                if (state.profile && state.profile.uuid === profileuuid) {
                    state.profile = null;
                }
            }
        },
        deleteProfileFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        //Crear perfil
        createNewProfileStart(state) {
            state.loading = true;
            state.error = null;
        },
        createNewProfileSuccess(state, action) {
            state.loading = false;
            state.profile = action.payload;
            state.successMessage = 'Perfil creado exitosamente';
            state.errorMessage = null;
        },
        createNewProfileFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.errorMessage = 'Error al crear perfil';
            state.successMessage = null;
        },
    },
});

export const fetchProfiles = withAuthentication((authToken, ...args) => async (dispatch, getState) => {
    dispatch(fetchProfilesStart());
    try {
        const profiles = await getProfilesWithSearch(authToken, ...args);
        dispatch(fetchProfilesSuccess(profiles));
    } catch (error) {
        dispatch(fetchProfilesFailure(error.message));
    }
});

export const fetchProfileById = withAuthentication((authToken, profileuuid) => async (dispatch, getState) => {
    dispatch(fetchProfileStart());
    try {
        const profile = await getProfileById(authToken, profileuuid);
        dispatch(fetchProfileSuccess(profile));
    } catch (error) {
        dispatch(fetchProfileFailure(error.message));
    }
});

export const createNewProfile = withAuthentication((authToken, newProfile) => async (dispatch, getState) => {
    dispatch(createNewProfileStart());
    try {
        const profile = await createProfile(authToken, newProfile);
        dispatch(createNewProfileSuccess(profile));
    } catch (error) {
        dispatch(createNewProfileFailure(error.message));
    }
});

export const editProfile = withAuthentication((authToken, profileuuid, editedProfile) => async (dispatch, getState) => {
    dispatch(editProfileStart());
    try {
        const profile = await editProfileById(authToken, profileuuid, editedProfile);
        dispatch(editProfileSuccess(profile));
    } catch (error) {
        dispatch(editProfileFailure(error.message));
    }
});

export const deleteProfile = withAuthentication((authToken, profileuuid) => async (dispatch, getState) => {
    dispatch(deleteProfileStart());
    try {
        await deleteProfileById(authToken, profileuuid)
        dispatch(deleteProfileSuccess(profileuuid));
    } catch (error) {
        dispatch(deleteProfileFailure(error.message));
    }
});

export const {
    fetchProfilesStart,
    fetchProfilesSuccess,
    fetchProfilesFailure,
    fetchProfileStart,
    fetchProfileSuccess,
    fetchProfileFailure,
    clearProfile,
    editProfileStart,
    editProfileSuccess,
    editProfileFailure,
    editCloseMessage,
    deleteProfileStart,
    deleteProfileSuccess,
    deleteProfileFailure,
    createNewProfileStart,
    createNewProfileSuccess,
    createNewProfileFailure,
} = profilesSlice.actions;

export const selectProfiles = (state) => state.profile.data;
export const selectProfileById = (state) => state.profile.profile;
export const selectProfilesLoading = (state) => state.profile.loading;
export const selectProfilesError = (state) => state.profile.error;

export default profilesSlice.reducer;
