import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, ListGroup, Button, ButtonGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProviders, selectProviders, selectProvidersError, selectProvidersLoading, selectProviderById, clearProvider, fetchProviderById } from '../../redux/reducers/providersReducer'
import { selectLoggedUserProfile } from '../../redux/reducers/authReducer';
import { PencilSquare, Trash } from 'react-bootstrap-icons';
import SearchBar from '../../components/Searchbar';
import '../styles/Providers.css'
import NewProviderModal from './NewProviderModal';
import EditProviderModal from './EditProviderModal';
import DeleteProviderModal from './DeleteProviderModal';

const Providers = () => {
    // Llamadas a API y Redux
    const dispatch = useDispatch();
    const loading = useSelector(selectProvidersLoading);
    const providers = useSelector(selectProviders);
    const error = useSelector(selectProvidersError);
    const provider = useSelector(selectProviderById);
    const profilesAuth = useSelector(selectLoggedUserProfile);

    useEffect(() => {
        dispatch(fetchProviders(null));
    }, [dispatch]);

    const hasManageProvidersAccess = profilesAuth?.some(profile =>
        profile.accesses?.some(access => access.name === 'manage_providers')
    );

    //Popups
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showNewEntityModal, setShowNewEntityModal] = useState(false);

    const handleEditOpen = (provider_uuid) => {
        dispatch(fetchProviderById(provider_uuid));
        setShowEditModal(true);
    };

    const handleEditClose = () => {
        dispatch(clearProvider());
        setShowEditModal(false);
    };

    const handleDeleteOpen = (provider_uuid) => {
        dispatch(fetchProviderById(provider_uuid));
        setShowDeleteModal(true);
    };

    const handleDeleteClose = (provider_uuid) => {
        dispatch(clearProvider());
        setShowDeleteModal(false);
    };

    const handleNewEntityOpen = () => {
        setShowNewEntityModal(true);
    };

    const handleNewEntityClose = () => {
        setShowNewEntityModal(false);
    };

    //Searchbar
    const handleSearch = (text) => {
        dispatch(fetchProviders(text));
    };

    return (
        <Container className='pt-4 pb-4'>
            <Row>
                <Col>
                    <Container className='pb-4 align-items-center'>
                        <Row className="align-items-center">
                            <Col sm='3' md='3' lg='3'>
                                <h1 style={{ marginBottom: 0 }}>Proveedores</h1>
                            </Col>
                            <Col sm='7' md='7' lg='7'>
                                <SearchBar onSearch={handleSearch} parameterName={"nombre"} />
                            </Col>
                            {hasManageProvidersAccess && (
                                <Col sm='2' md='2' lg='2'>
                                    <Button variant='primary' style={{ marginTop: 0, padding: '0.8rem 1rem', marginLeft: '10px', backgroundColor: '#0d6efd', borderColor: '#0d6efd' }} onClick={handleNewEntityOpen}>Nuevo proveedor</Button>
                                </Col>)}
                        </Row>
                    </Container>
                    {loading ? (
                        <p>Cargando...</p>
                    ) : error ? (
                        <p>Error: {error}</p>
                    ) : providers.length === 0 ? (
                        <p>No se encontraron proveedores.</p>
                    ) : (
                        <div className="align-items-center">
                            <ListGroup className='table-header'>
                                <ListGroup.Item className="d-flex justify-content-between align-items-center" style={{ backgroundColor: '#000000', border: '0' }}>
                                    <Row style={{ width: '100%' }}>
                                        <Col sm='4' md='4' lg='4' className="d-flex justify-content-center table-header-item">
                                            <p><strong>Nombre</strong></p>
                                        </Col>
                                        <Col sm='3' md='3' lg='3' className="d-flex justify-content-center table-header-item">
                                            <p><strong>Teléfono</strong></p>
                                        </Col>
                                        <Col sm='3' md='3' lg='3' className="d-flex justify-content-center table-header-item">
                                            <p><strong>Tipo</strong></p>
                                        </Col>
                                        <Col sm='2' md='2' lg='2' className="d-flex justify-content-center table-header-item">
                                            <p><strong>Acciones</strong></p>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            </ListGroup>
                            <ListGroup>
                                {providers.map(provider => (
                                    <ListGroup.Item key={provider.uuid} className="d-flex justify-content-between align-items-center mb-1" style={{ backgroundColor: '#36454F', border: '0' }}>
                                        <Row className="align-items-center" style={{ padding: '5px 0', width: '100%' }}>
                                            <Col sm='4' md='4' lg='4' className="d-flex justify-content-center">
                                                <Card.Text><strong>{provider.name}</strong></Card.Text>
                                            </Col>
                                            <Col sm='3' md='3' lg='3' className="d-flex justify-content-center">
                                                <Card.Text>{provider.phone}</Card.Text>
                                            </Col>
                                            <Col sm='3' md='3' lg='3' className="d-flex justify-content-center">
                                                <Card.Text>{provider.is_national ? 'Nacional' : 'Internacional'}</Card.Text>
                                            </Col>
                                            <Col sm="2" md="2" lg="2" className="d-flex justify-content-center">
                                                {hasManageProvidersAccess && (
                                                    <div className="d-flex">
                                                        <ButtonGroup>
                                                            <Button variant="danger" style={{ backgroundColor: '#0d6efd', borderColor: '#0d6efd' }} size="lg" onClick={() => handleEditOpen(provider.uuid)}><PencilSquare /></Button>
                                                            <Button variant="danger" size="lg" onClick={() => handleDeleteOpen(provider.uuid)}><Trash /></Button>
                                                        </ButtonGroup>
                                                    </div>
                                                )}
                                            </Col>

                                        </Row>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </div>
                    )}


                    {/*POPUPS*/}
                    <NewProviderModal showNewEntityModal={showNewEntityModal} handleClose={handleNewEntityClose} handleSearch={handleSearch} />
                    <EditProviderModal showEditModal={showEditModal} handleClose={handleEditClose} provider={provider} handleSearch={handleSearch} />
                    <DeleteProviderModal showDeleteModal={showDeleteModal} handleClose={handleDeleteClose} provider={provider} handleSearch={handleSearch} />
                </Col>
            </Row>
        </Container >
    );
}

export default Providers