import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { editCategory, editCloseMessage, selectCategoriesErrorMessage, selectCategoriesSuccessMessage } from '../../redux/reducers/categoriesReducer';

const EditCategoryModal = ({ showEditModal, handleClose, handleSearch, category }) => {
    // Llamadas a API y Redux
    const dispatch = useDispatch();

    //Mensaje de exito o error
    const successMessage = useSelector(selectCategoriesSuccessMessage);
    const errorMessage = useSelector(selectCategoriesErrorMessage);

    const handleCloseMessage = () => {
        handleClose();
        setFormData({
            name: ''
        })
        dispatch(editCloseMessage());
        handleSearch();
    }

    //Nombre y descripcion
    const [formData, setFormData] = useState({
        name: ''
    });

    const handleFormChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    useEffect(() => {
        if (category) {
            setFormData({
                name: category.name
            });
            setInitialFormData({
                name: category.name
            });
        }
    }, [category]);

    //Cambios de formulario
    const [initialFormData, setInitialFormData] = useState();
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        if (initialFormData) {
            const hasChanges = JSON.stringify(initialFormData) !== JSON.stringify(formData);
            setIsChanged(hasChanges);
        }
    }, [formData, initialFormData]);

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(editCategory(category.uuid, formData));
    };

    return (
        <div>
            <Modal show={showEditModal} onHide={handleClose} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Editar categoría</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ marginLeft: '8px' }}>
                    <form style={{ display: 'contents' }} onSubmit={handleSubmit}>
                        <div>
                            <Row className='pb-2'>
                                <Col>
                                    <InputGroup>
                                        <InputGroup.Text>Nombre:</InputGroup.Text>
                                        <Form.Control
                                            name="name"
                                            type="text"
                                            placeholder="Nombre profesional..."
                                            value={formData.name}
                                            onChange={handleFormChange}
                                            style={{ marginTop: 0, height: '100%' }}
                                            required
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                        </div>
                        <Modal.Footer>
                            {!isChanged && (
                                <p className="form-text text-muted">
                                    Debe realizar cambios para guardar
                                </p>
                            )}
                            <Button variant="primary" style={{ marginTop: '0px' }} onClick={handleSubmit} disabled={!isChanged}>Guardar cambios</Button>
                        </Modal.Footer>
                    </form>
                </Modal.Body>
                <Modal show={!!successMessage || !!errorMessage} onHide={handleCloseMessage}>
                    <Modal.Header closeButton>
                        <Modal.Title>{errorMessage ? 'Error en la modificación' : 'Modificación exitosa'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {successMessage || errorMessage}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseMessage}>Cerrar</Button>
                    </Modal.Footer>
                </Modal>
            </Modal >
        </div>
    );
}

export default EditCategoryModal;