import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createNewProvider, editCloseMessage, selectProvidersErrorMessage, selectProvidersSuccessMessage } from '../../redux/reducers/providersReducer';

const NewProviderModal = ({ showNewEntityModal, handleClose, handleSearch }) => {
    // Llamadas a API y Redux
    const dispatch = useDispatch();

    //Mensaje de exito o error
    const successMessage = useSelector(selectProvidersSuccessMessage);
    const errorMessage = useSelector(selectProvidersErrorMessage);

    const handleCloseMessage = () => {
        handleClose();
        setFormData({
            name: '',
            phone: '',
            is_national: true
        })
        dispatch(editCloseMessage());
        handleSearch();
    }

    const handleTypeChange = (event) => {
        if (event.target.value === 'national') {
            setFormData({ ...formData, is_national: true });
        } else {
            setFormData({ ...formData, is_national: false });
        }
    };

    //Nombre y descripcion
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        is_national: true
    });

    const handleFormChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(createNewProvider(formData));
    };

    return (
        <div>
            <Modal show={showNewEntityModal} onHide={handleClose} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Nuevo proveedor</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ marginLeft: '8px' }}>
                    <form style={{ display: 'contents' }} onSubmit={handleSubmit}>
                        <div>
                            <Row className='pb-2'>
                                <Col sm="5" md="5" lg="5">
                                    <InputGroup>
                                        <InputGroup.Text>Nombre:</InputGroup.Text>
                                        <Form.Control
                                            name="name"
                                            type="text"
                                            placeholder="Nombre profesional..."
                                            value={formData.name}
                                            onChange={handleFormChange}
                                            style={{ marginTop: 0, height: '100%' }}
                                            required
                                        />
                                    </InputGroup>
                                </Col>
                                <Col sm="4" md="4" lg="4">
                                    <InputGroup>
                                        <InputGroup.Text>Teléfono:</InputGroup.Text>
                                        <Form.Control
                                            name="phone"
                                            type="number"
                                            placeholder="Número de teléfono..."
                                            value={formData.phone}
                                            onChange={handleFormChange}
                                            style={{ marginTop: 0, height: '100%' }}
                                            required
                                        />
                                    </InputGroup>
                                </Col>
                                <Col sm="3" md="3" lg="3">
                                    <InputGroup>
                                        <Form.Select
                                            as="select"
                                            value={formData.type}
                                            onChange={handleTypeChange}
                                            style={{
                                                marginTop: 0,
                                                height: '100%',
                                                paddingRight: '1.5rem',
                                            }}
                                        >
                                            <option value="national">Nacional</option>
                                            <option value="international">Internacional</option>
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </div>
                        <Modal.Footer>
                            <Button variant="primary" type='submit'>Crear proveedor</Button>
                        </Modal.Footer>
                    </form>
                </Modal.Body>
                <Modal show={!!successMessage || !!errorMessage} onHide={handleCloseMessage}>
                    <Modal.Header closeButton>
                        <Modal.Title>{errorMessage ? 'Error en la creación' : 'Creación exitosa'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {successMessage || errorMessage}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseMessage}>Cerrar</Button>
                    </Modal.Footer>
                </Modal>
            </Modal >


        </div>
    );
}

export default NewProviderModal;