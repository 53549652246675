import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Col, Container, Row, ListGroup, Card } from 'react-bootstrap';
import SearchBar from '../../components/Searchbar';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers, fetchUserById, selectUsers, selectUsersError, selectUsersLoading, clearUser, selectUserById } from '../../redux/reducers/usersReducer';
import NewUserModal from './NewUserModal';
import DeleteUserModal from './DeleteUserModal';
import EditUserModal from './EditUserModal';
import { PersonCircle, PencilSquare, Trash } from 'react-bootstrap-icons';
import '../styles/User.css'

const Users = () => {
    // Llamadas a API y Redux
    const dispatch = useDispatch();
    const loading = useSelector(selectUsersLoading);
    const users = useSelector(selectUsers);
    const error = useSelector(selectUsersError);
    const user = useSelector(selectUserById);

    useEffect(() => {
        dispatch(fetchUsers(''))
    }, [dispatch]);

    const handleSearch = (text) => {
        dispatch(fetchUsers(text))
    }

    //Popups
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showNewUserModal, setShowNewUserModal] = useState(false);

    const handleNewUserOpen = () => {
        setShowNewUserModal(true);
    };

    const handleNewUserClose = () => {
        setShowNewUserModal(false);
        dispatch(fetchUsers(''))
    };

    const handleDeleteClose = () => {
        dispatch(clearUser())
        setShowDeleteModal(false);
        dispatch(fetchUsers(''));
    };

    const handleEditClose = () => {
        dispatch(clearUser());
        setShowEditModal(false);
        dispatch(fetchUsers(''));
    };

    const handleDeleteOpen = (uuiduser) => {
        dispatch(fetchUserById(uuiduser));
        setShowDeleteModal(true);
    };

    const handleEditOpen = (useruuid) => {
        dispatch(fetchUserById(useruuid));
        setShowEditModal(true);
    };

    return (
        <Container className='pt-4 pb-4'>
            <Container className='pb-4 align-items-center'>
                <Row className="align-items-center">
                    <Col sm='3' md='3' lg='3'>
                        <h1 style={{ marginBottom: 0 }}>Usuarios</h1>
                    </Col>
                    <Col sm='7' md='7' lg='7'>
                        <SearchBar onSearch={handleSearch} parameterName={"nombre o apellido"} />
                    </Col>
                    <Col sm='2' md='2' lg='2'>
                        <Button variant='primary' style={{ marginTop: 0, padding: '0.8rem 1rem', marginLeft: '10px', backgroundColor: '#0d6efd', borderColor: '#0d6efd' }} onClick={handleNewUserOpen}>Nuevo usuario</Button>
                    </Col>
                </Row>
            </Container>
            {loading ? (
                <p>Cargando...</p>
            ) : error ? (
                <p>Error: {error}</p>
            ) : users.length === 0 ? (
                <p>No se encontraron usuarios</p>
            ) : (
                <>
                    <ListGroup>
                        {users.map(user => (
                            <ListGroup.Item key={user.uuid} className="d-flex justify-content-between align-items-center mb-3" style={{ backgroundColor: '#36454F', border: '0' }}>
                                <div className="d-flex align-items-center" style={{ width: '100%' }}>
                                    <PersonCircle size={35} className="me-3" color='white' />
                                    <div style={{ width: '35%' }}>
                                        <Card.Text className="mb-2"><strong>{user.name}</strong></Card.Text>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <ButtonGroup>
                                        <Button variant="danger" style={{ backgroundColor: '#0d6efd', borderColor: '#0d6efd' }} size="lg" onClick={() => handleEditOpen(user.uuid)}><PencilSquare /></Button>
                                        <Button variant="danger" size="lg" onClick={() => handleDeleteOpen(user.uuid)}><Trash /></Button>
                                    </ButtonGroup>
                                </div>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </>
            )
            }

            {/*POPUPS*/}
            <NewUserModal handleClose={handleNewUserClose} showNewUserModal={showNewUserModal} />
            <EditUserModal showEditModal={showEditModal} handleClose={handleEditClose} user={user} />
            <DeleteUserModal showDeleteModal={showDeleteModal} handleClose={handleDeleteClose} user={user} />
        </Container >
    )
}

export default Users;